/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply outline-primary-dark;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
}

.mat-mdc-row:hover {
  @apply hover:bg-gray cursor-pointer
}

.mat-mdc-header-cell {
  @apply !border-b-gray-dark !border-b-2 text-secondary-dark
}

.mat-mdc-cell {
  @apply !border-b-gray-dark text-secondary
}

.mat-sort-header-arrow {
  @apply !text-primary
}

.mat-mdc-paginator {
  @apply !text-secondary-light
}

[mat-sort-header].cdk-keyboard-focused .mat-sort-header-container,
[mat-sort-header].cdk-program-focused .mat-sort-header-container {
  @apply !border-primary
}

.mat-mdc-notch-piece {
  border-style: initial;
}

.readonly:not(.disabled) .mat-mdc-notch-piece {
  border-color: rgba(26, 28, 28, 0.12) !important;
}

.readonly:not(.disabled) .mat-mdc-notch-piece.mdc-notched-outline__notch {
  border-left-color: transparent !important;
}

fieldset {
  @apply border p-4 rounded;
}

legend {
  @apply px-2 -mx-2 text-secondary-lighter;
}

.demo-chart>div:has(.data-view) {
  @apply shadow-xl;
  border-width: 1px;
  border-radius: 0.25rem;
  padding: 1rem !important;
  width: 80%;
  height: 80%;
  margin: auto;
  overflow: hidden;
}

.demo-chart>div:has(.data-view)>h4 {
  display: none;
}

.demo-chart>div:has(.data-view) div:first-of-type {
  height: calc(100% - 40px) !important;
}

.demo-chart>div:has(.data-view) div:nth-of-type(2) {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding-top: 1rem;
  position: relative !important;
  bottom: unset !important;
}

.demo-chart>div:has(.data-view) div:nth-of-type(2)>div {
  background-color: #db001b !important;
  margin: 0 !important;
  float: none !important;
}

.demo-chart>div:has(.data-view) .data-view {
  width: 100%;
  height: 100%;
  float: left;
  padding: 1rem;
  border-width: 1px;
  border-radius: 0.25rem
}

.submenu-link.active {
  background: red;
}

thead th {
  font-weight: bold !important;
}

tfoot td {
  @apply border-t;
  font-weight: bold;
}

.ag-center-cols-container,
.ag-floating-bottom-container {
  min-width: 100%;
}

.no-rows .ag-center-cols-viewport {
  min-height: 150px !important;
}
.ag-center-cols-viewport {
  min-height: 40px !important;
}

.mdc-button__label {
  width: 100%;
}

.sidebar-component .content-wrapper {
  height: calc(100% - 61px);
}